import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { BackgroundImage } from "../components/Images"
import { LogoHeading } from "../components/Layout/Heading"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Layout/Seo"
import { NormalA } from "../components/Reusable/Button"

const ThankYouPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Thank you!" />
      <BackgroundImage />
      <LogoHeading />
      <br></br>
      <Main>
        <h1>{t("Tack för ditt meddelande!")}</h1>
        <NormalA arrow={false} href="/">
          {t("Tillbaka!")}
        </NormalA>
      </Main>
    </Layout>
  )
}
const Main = styled.div`
  padding: 40px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
`

export default ThankYouPage
